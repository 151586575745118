import { Avatar } from "@mui/material";

const AppLogo = () => {
  return (
    <Avatar
      src={"/logo_no_bg.png"}
      sx={{
        width: 140,
        height: 140,
        borderRadius: "50%",
        backgroundColor: "white",
        marginX: "auto",
        padding: 4,
      }}
    />
  );
};

export default AppLogo;
