import { Container, Stack } from "@mui/material";
import PageTitle from "../../components/page/PageTitle";
import SendMessageForm from "./SendMessageForm";

const SendMessage = () => {
  return (
    <Stack
      sx={{
        minHeight: "100vh",
        py: 4,
      }}
    >
      <Container>
        <PageTitle title="الزائرون" />
        <SendMessageForm />
      </Container>
    </Stack>
  );
};

export default SendMessage;
