import React, { ReactNode } from "react";
import { Container, Stack } from "@mui/material";

const PageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      id="page-wrapper"
      sx={{
        backgroundColor: "#FFFFFF",
        minHeight: { sm: "calc( 100vh - 100px )", md: "calc( 100vh - 40px )" },
        borderRadius: 2,
      }}
    >
      <Stack gap={2} sx={{ paddingY: "24px" }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default PageWrapper;
