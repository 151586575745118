import React, { useState } from "react";
// r redux
import { useSelector } from "react-redux";
// mui
import { Stack } from "@mui/material";
// components
import PageGuard from "../../components/page/PageGuard";
import PageWrapper from "../../components/page/PageWrapper";
import PageTitle from "../../components/page/PageTitle";
import FiltersContainer from "../../components/filters/FiltersContainer";
import SearchBox from "../../components/filters/SearchBox";
import SortFilter from "../../components/filters/SortFilter";
import ThreeDotsLoader from "../../components/loader/ThreeDotsLoader";
import PinCard from "../../components/cards/PinCard";
import UserFilter from "../../components/filters/UserFilter";
import OtpMessageCard from "../../components/cards/OtpMessageCard";
import PagePagination from "../../components/filters/PagePagination";
// utils
import { defaultValues } from "../../lib/defaultValues";
// hooks
import usePageParams from "../../hooks/usePageParams";
import { useFetchApi } from "../../hooks/useFetchApi";
// types
type TSortOptions = {
  sortTitle: string;
  sortKey: string;
};
const sortingOptions: TSortOptions[] = [
  {
    sortTitle: "تاريخ الطلب من الاحدث الي الاقدم",
    sortKey: "-requested_at",
  },
  {
    sortTitle: "تاريخ الطلب من الاقدم الي الاحدث",
    sortKey: "requested_at",
  },
];
const PinsHistory = () => {
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  const { page, setPage } = usePageParams();
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("-requested_at");
  const [filterValues, setFilterValues] = useState({
    user_id: "",
  });
  const {
    data: pins,
    loading,
    error,
  } = useFetchApi(
    `/pin-history?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&q=${searchValue}&filters=${
      filterValues.user_id ? `user_id:${filterValues.user_id}` : ""
    }`,
    userToken,
    [page, itemsPerPage, sortValue, searchValue, filterValues]
  );
  return (
    <PageGuard
      pageKey={[
        "/api/v1/pin-history:index:admin",
        "/api/v1/pin-history:index:own",
      ]}
    >
      <PageWrapper>
        <PageTitle title="رسائل التحقق" />
        <FiltersContainer>
          <SearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <SortFilter
            sortValue={sortValue}
            setSortValue={setSortValue}
            sortingOptions={sortingOptions}
          />
          <UserFilter
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        </FiltersContainer>
        <Stack
          direction="row"
          gap="12px"
          justifyContent="center"
          flexWrap="wrap"
        >
          {loading ? (
            <ThreeDotsLoader />
          ) : pins?.data?.length === 0 ? (
            <p className="center-text">لا يوجد عناصر لعرضها</p>
          ) : (
            pins?.data?.map((pin: OtpMessage) => (
              <OtpMessageCard
                key={pin.id}
                phone={pin.body.phone}
                content={pin.body.content}
                requested_at={pin.requested_at}
              />
            ))
          )}
        </Stack>
        <PagePagination
          totalItems={pins?.meta?.total}
          itemsPerPage={itemsPerPage}
          page={page}
          setPage={setPage}
        />
      </PageWrapper>
    </PageGuard>
  );
};

export default PinsHistory;
