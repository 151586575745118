// global styles
import "./styles/global.css";
// react router
import { RouterProvider } from "react-router-dom";
// proviers
import { CacheProvider, ThemeProvider } from "@emotion/react";
// routers
import router from "./routes/router";
// them utils
import { RtlTheme, cacheRtl } from "./themeUtils/RtlTheme";
// r redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";
function App() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={RtlTheme}>
        <Provider store={store}>
          <RouterProvider router={router} />
          <SnackbarProvider />
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
