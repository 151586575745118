import React, { ChangeEvent, useState } from "react";
// router
import { useNavigate } from "react-router";
// mui
import { FormControlLabel, Stack, Switch } from "@mui/material";
// components
import PageWrapper from "../../components/page/PageWrapper";
import PageTitle from "../../components/page/PageTitle";
import InputField from "../../components/form-components/InputField";
import SubmitButton from "../../components/buttons/SubmitButton";
import PasswordField from "../../components/form-components/PasswordField";
// react hook form  and validation
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../schemes/auth/loginSchema";
// call api
import { apiFetch } from "../../api/apiFetch";
// react redux
import { useDispatch } from "react-redux";
import { setUserToken, setUserData } from "../../redux/features/user/userSlice";
// alert
import { fireAlert } from "../../lib/fireAlert";
// utils
import { keysLables } from "../../lib/keysLabels";
// types
type TFormValues = {
  email: string;
  password: string;
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<TFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver<any>(loginSchema),
    mode: "onSubmit",
  });

  const loginSubmit = async (values: any) => {
    setSubmitting(true);
    const formData = new FormData();
    for (let key in values) formData.append(key, values[key]);
    const login = await apiFetch(
      "/login",
      "POST",
      { "content-type": "application/json" },
      JSON.stringify(values)
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.token) throw new Error(data.message);
        const getUserData = apiFetch(
          "/me",
          "GET",
          { Authorization: `Bearer ${data.token.value}` },
          null
        )
          .then((meRes) => meRes.json())
          .then((meData) => {
            if (meData.status === 403) {
              fireAlert(
                "لا يمكنك الدخول لانك لا تملك الاذن للحصول علي بيانات حسابك",
                "error"
              );
              setSubmitting(false);
              return;
            }
            dispatch(setUserData(meData));
            dispatch(setUserToken(data.token.value));
            fireAlert("مرحبا بك في لوحة تحكم رسالة", "success");
            setSubmitting(false);
            navigate("/welcome");
          });
      })
      .catch((error) => {
        fireAlert(error.message ?? "", "error");
        setSubmitting(false);
      });
  };
  return (
    <PageWrapper>
      <PageTitle title="تسجيل الدخول" />
      <Stack
        component={"form"}
        onSubmit={handleSubmit(loginSubmit)}
        gap="12px"
        maxWidth="700px"
        margin="auto"
        width="100%"
      >
        <InputField
          name="email"
          type="email"
          label={keysLables["email"]}
          register={{ ...register("email") }}
          errorMessage={errors?.email?.message}
        />
        <PasswordField
          label={keysLables["password"]}
          register={{ ...register("password") }}
          errorMessage={errors?.password?.message}
        />

        <SubmitButton label="تسجيل الدخول" disabled={submitting} />
      </Stack>
    </PageWrapper>
  );
};

export default Login;
