import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
const UserExist = ({ children }: { children: ReactNode }) => {
  const userToken = useSelector(
    (state: UserGlobalState) => state.user.userToken
  );
  return userToken ? <Navigate to="/" /> : <>{children}</>;
};

export default UserExist;
