export const keysLables = {
  email: "البريد الالكتروني",
  name: "الاسم",
  password: "كلمة المرور",
  password_confirmation: "تاكيد كلمة المرور",
  birthdate: "تاريخ الميلاد",
  roles: "الدور",
  is_disabled: "معطل",
  is_verified: "مؤكد",
  company: "اسم الشركة",
  account_expiry: "تاريخ انتهاء الحساب",
  key: "مفتاح الدخول",
  phone: "رقم الهاتف",
  content: "محتوي الرسالة",
  sender_id: "اسم المرسل",
};
