import React from "react";
import { adminLinks } from "./adminLinks";
// mui
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
// react redux hooks
import useHavePermission from "../../hooks/useHavePermission";
import { IconType } from "react-icons";
type Link = {
  title: string;
  icon: IconType;
  path: string;
  key: string[];
};
const LinksDrawer = () => {
  return (
    <div>
      <List>
        {adminLinks.map((link, index) => (
          <LinkDrawer link={link} key={link.path} />
        ))}
      </List>
    </div>
  );
};

export default LinksDrawer;

const LinkDrawer = ({ link }: { link: Link }) => {
  const { loading, havePermission } = useHavePermission(link.key);
  const isAllowed = havePermission();
  return !isAllowed || loading ? (
    <></>
  ) : (
    <ListItem key={link.path} disablePadding sx={{ color: "white" }}>
      <ListItemButton component={Link} to={link.path}>
        <ListItemIcon
          sx={{ fontSize: "22px", minWidth: "32px", color: "inherit" }}
        >
          {<link.icon />}
        </ListItemIcon>
        <ListItemText primary={link.title} />
      </ListItemButton>
    </ListItem>
  );
};
