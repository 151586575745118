import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
// react router
import { Navigate, Outlet } from "react-router";

const ProtectedRoutes = ({ children }: { children: ReactElement }) => {
  // const [isAuth, setIsAuth] = useState(true);
  const userToken = useSelector((state: any) => state.user.userToken);
  return userToken ? <>{children}</> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
