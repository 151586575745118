import { jwtDecode } from "jwt-decode";
import { fireAlert } from "../lib/fireAlert";
import { store } from "../redux/store";
export const apiFetch = async <T = any>(
  endpoint: string,
  method: string = "GET",
  headers?: HeadersInit,
  body?: any,
  actionKey?: string
): Promise<any> => {
  // if (actionKey) {
  //   console.log("store", store);
  // }
  if (headers?.hasOwnProperty("Authorization")) {
    if (isTokenExpired()) {
      window.localStorage.removeItem("user_token");
      fireAlert("انتهت فترة سماحية الدخول, برجاء تسجيل الدخول مرة اخرى.");
      window.location.href = "/login";
      return 0;
    }
  }
  const fetchRes = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method,
    headers,
    body: body ?? null,
  });

  return fetchRes;
};

function isTokenExpired() {
  if (window.localStorage.getItem("user_token")) {
    const token = window.localStorage.getItem("user_token");
    const decodedToken = jwtDecode(`${token}`);
    if (decodedToken.exp) {
      // if there is no exp
      const exp = (decodedToken.exp || 1) * 1000;
      return exp <= Date.now();
    }
    return false;
  }
  return false;
}
