import React, { ReactNode, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { fetchPermissions } from "../../redux/features/permissions/permissionsSlice";
import useHavePermission from "../../hooks/useHavePermission";

type TProps = {
  children: ReactNode;
  pageKey: string[];
};

const PageGuard = ({ children, pageKey }: TProps) => {
  const userData = useSelector((state: UserGlobalState) => state.user.userData);

  const { loading, havePermission } = useHavePermission(pageKey);
  const isAllowd = havePermission();
  if (loading) return <p>...</p>;
  return isAllowd ? (
    <>{children}</>
  ) : (
    <Navigate to={userData.id ? "/welcome" : "/login"} />
  );
};

export default PageGuard;
